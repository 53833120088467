import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="MHM Online" keywords={[`MHM`]} />
    <h1>Tervetuloa MHM Onlineen</h1>

    <p>
      MHM, alunperin <em>Maso Software Hockey Manager</em>, on vuosituhannen
      vaihteessa mainetta niittänyt suomalainen jääkiekkomanageripelien sarja.
      Minä olen Mikko Forsström a.k.a <em>Pekkis</em>, pelien tekijä.
    </p>

    <p>
      Suunnittelemani ja toteuttamani MHM-trilogia noteerattiin näyttävästi
      kaikissa Suomen suurimmissa peli- ja tietokonelehdissä, ja kaikki kolme
      peliä voittivat vuorollaan MikroBitin "kuukauden peli"-palkinnon. Hyvä
      niin, koska ilman Mikrobittiä ja sen pelitoimittajaa Jukka O. Kauppista
      tietoisuus peleistäni ei olisi koskaan lähtenyt leviämään. Olen
      Mikrobitille velkaa tosi paljon, todennäköisesti koko urani ja sen missä
      olen tänään!
    </p>

    <p>
      Vuosien mittaan pelini keräsivät ympärilleen uskollisten fanien joukon, ja
      MHM:llä oli ystäviä yllättävissäkin paikassa. Muistan jutelleeni
      puhelimessa MHM:ää fanittavien jääkiekonpelaajien, yhden liigassa
      tuominneen ammattituomarin ja monen muunkin julkkiksen kanssa. Ne olivat
      jänniä juttuja, voin vakuuttaa, parikymppiselle klopille!
    </p>

    <p>
      Kolmas pelini, MHM 2000, osoittautui jättimäiseksi menestykseksi mutta jäi
      silti pitkäksi aikaa sarjan joutsenlauluksi. Webbi imaisi minut
      syövereihinsä, ja lisäksi pelkäsin omaa onnistumistani niin paljon etten
      alitajuisesti koskaan oikein uskonut seuraavan pelini voivan päihittää
      "kakstonnista".
    </p>

    <p>
      MHM:n ansiosta koodaamisesta tuli itselleni ammatti. Koodaan webbiä
      päätoimisesti, ja pyöritin pitkään yhdessä kaverieni kanssa perustamaamme
      <a target="_blank" href="https://fraktio.fi">
        Fraktio
      </a>
      -nimistä koodipajaa. IT-alalla tulee hämmentävän usein vastaan vanhoja
      faneja, joita olen onnistunut inspiroimaan. Tuntuu lämpimältä sydämessä,
      kun kuulen kauniita sanoja siitä kuinka suuren vaikutuksen olen
      onnistununut vaatimattomilla tekeleilläni saamaan aikaan.
    </p>

    <p>
      Kahdeksan tuntia päivässä jatkuva työelämän kujanjuoksu ei tietysti ole
      tehnyt hyvää uuden MHM:n ilmestymisaikataululle. Pitkät vuodet eivät
      kuitenkaan ole valuneet ihan hukkaan, koska koodausteknisesti olen
      tietysti nykyään ihan eri planeetalla kuin kohta neljännesvuosisata
      sitten. Huumorintajunikin on edelleen omanlaisensa, joten olen kyllä
      edelleen riittävän [epä]kypsä tuottamaan uuden MHM:n heti kun sen aika
      koittaa!
    </p>

    <h2>MHM 2020</h2>

    <p>
      Vuodesta 2019 alkaen olen jälleen yhtenä uuden MHM:n inkarnaationa tehnyt
      peleistäni rehtejä remakeja. Aloitin MHM 97:sta ihan sen takia että se on
      kaikkein yksinkertaisin, ja kun tämä POC oli valmis siirryin suoraan
      kakstonniseen.
    </p>

    <p>
      Ajatuksenani oli päästä maaliin vuoden 2019 puolella, mutta vuosi ei
      osoittautunut työelämällisesti kaikkein helpoimmaksi. Vuoden lopulla
      toteutin lopulta ns. kovan Pexitin "omasta" IT-putiikista. Nyttemmin
      vaikutan{' '}
      <a target="_blank" href="https://nitor.fi">
        Nitorilla
      </a>
      , ja ilokseni olen huomannut että yrittäjyydestä luopuminen on vapauttanut
      luovuuttani ja kakstonnisen remake kehittyy vuonna 2020 ripeämmin.
    </p>

    <p>
      Pexit vapautti luovuuden lisäksi jonkun verran pääomaa, joten
      periaatteessa olisin "vapaa" vaikka tekemään täyspäiväisesti pelihommia
      muutamaksi vuodeksi. Mutta sitä olen päättänyt katselevani lähemmin siinä
      kohtaa kun MHM 2000:n remake on valmis ja olen tavalla tai toisella
      fundeerannut että voisiko uuden MHM:n tekemisessä olla jonkunlaista
      järkevää bisnestä. Ihan pelkän huvin ja urheilun vuoksi en haluaisi siihen
      lähteä vaikka ajatus kiistämättä houkuttaakin!
    </p>

    <p>
      Niin tai näin, molemmat remaket ovat vapaita ohjelmistoja avoimella
      lähdekoodilla, joten voit
      <a target="_blank" href="https://github.com/mhm-online">
        ihan itse käydä Githubissa toteamassa
      </a>{' '}
      millaista uusi koodini on ja miten MHM 2000 edistyy.
    </p>

    <div>
      <h2>
        <a href="/mhm-97">MHM 97</a>
      </h2>

      <p>
        Halusin 1990-luvun puolivälissä kovasti pelata jääkiekkomanageripeliä.
        Sellaista ei ollut, paitsi Hockey League Simulator joka oli epäpeli,
        joten minulle ei jäänyt muuta vaihtoehtoa kuin tehdä oma. Ei haitannut
        etten osannut lainkaan ohjelmoida, koska tekemällähän oppii.
      </p>

      <p>
        Parin vuoden vaihtelevan aktiivisuuden ja monien mutkien jälkeen{' '}
        <a href="/mhm-97">MHM 97</a> oli syntynyt. Päätin huvikseni julkaista
        pelini purkkeihin, joka johti mielenkiintoisiin ja yllättäviin
        käänteisiin elämässäni. Monet halusivat pelata peliäni, ja jotkut jopa{' '}
        <em>maksaa siitä ilosta</em>. Se oli täysin käsittämätöntä!
      </p>
    </div>

    <div>
      <h2>
        <a href="/mhm-99">MHM 99</a>
      </h2>

      <p>
        MHM 97:n menestys nousi minulle vähän hattuun. Luulin olevani suuri
        pelintekijä, ja aloitin monenlaisia peliprojekteja jotka eivät olleet
        jääkiekkomanageripelejä. Nämä projektit ovat sellaisia, että häpeillen
        pikkuisen punastun kun niitä nyt katselen.
      </p>

      <p>
        Kun pöly hetken päästä hälveni ja tein pakkolaskun maan pinnalle, päätin
        tehdä jatko-osan jääkiekkomanageripelilleni. Tällä kertaa kyseessä tuli
        olla ihan <strong>oikea</strong> manageripeli eikä pelkkää Enterin
        hakkaamista Pahkasikahumöörillä. <a href="/mhm-99">MHM 99</a> ei aivan
        osunut maaliin, ehkä siksi että kunnianhimoni oli suurempi kuin taitoni,
        mutta oli se silti askel oikeaan suuntaan.
      </p>
    </div>

    <div>
      <h2>
        <a href="/mhm-2000">MHM 2000</a>
      </h2>

      <p>
        Ensimmäisen trilogiani kolmas osa sai alkunsa henkisesti nihkeänä MHM
        99:n kausipäivityksenä. Saatuani opiskelupaikan päätin kuitenkin
        täräyttää vielä kerran, ja laajensin suunnitelmani kausipäivityksestä
        kokonaan uudeksi MHM-peliksi.
      </p>

      <p>
        Ensimmäistä kertaa nuori harjoitin tekemisessäni jonkinlaista
        suunnitelmallisuutta. Ohjelmoin työkaluja, piirtelin käppyröitä, mietin
        käyttöliittymää ja värejä, "palkkasin" testaajia ja teetätin nykyään
        kansainvälisesti tunnetuilla pelisuunnittelijoilla uskomattomia
        mahtigrafiikkoja.
      </p>

      <p>
        <a href="/mhm-2000">MHM 2000</a> myöhästyi puolisen vuotta mutta
        viehätti monia, mukaanlukien itseäni. Monien mielestä, minunkin
        mielestäni itse asiassa, MHM 2000 on edelleen yhdeksäntoista vuotta
        ilmestyttyään <em>maailman paras jääkiekkomanagerisimulaatio</em>.
      </p>
    </div>
  </Layout>
)

export default IndexPage
